import React from 'react'
import './styles.css'

const FeatureCard = ({ features }) => (

  <div className="feature-card__links">
    {features.map(feature => (
      <div className="feature-card__links-wrap">
        <div className="feature-card__link" to="/">
          <img className="feature-card__link-img" src={feature.image}/>
        </div>
        <div>
          <h3 className="feature-card__title">{feature.title}</h3>
          <div className="feature-card__text">{feature.description}</div>
        </div>
      </div>
    ))}
  </div>




)

export default FeatureCard
