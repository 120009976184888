import React from "react"
import Masthead from '../components/Masthead'
import Layout from "../components/Layout"
import SEO from "../components/seo"
import FeatureCards from "../components/FeatureCards"
import BlockSimple from '../components/BlockSimple'


const features = [{
    title: "Tenorio National Park",
    image: "/tenorio.jpg",
    description: "Tour to Tenorio National Park -Río Celeste- is one of the closest rainforests. Once rhere we can hike through the rainforest, see waterfalls and if lucky we'll see birds, sloths and frogs. We can swim in the waters of the celestial river, then enjoy a typical lunch. Guaranteed animals we'll see are sloths, frogs, toucans and many tree species. We recommend bringing: comfortable clothes, walking shoes, sandals, sunscreen, repellent, towels & clothes to change if necessary. There will be ponchos for sale and boots up for rent. The rate includes entrance to the parks, access to animal areas, lunch and transportation."
  },
  {
    title: "Palo Verde National Park",
    image: "/palo-verde.jpg",
    description: "Palo verde national park is a national conservation area, and one of the largest wetlands in Central America. We'll be able to navigate the river and observe the wild up close. We'll see a large variety of wildlife, such as birds, eagles, monkeys, crocodiles and much more. The tour is in the morning, followed by a healthy lunch in a local ranch in the area. We'll also visit GUAITI, a Chorotega indigenous culture area where we'll learn how to work with ceramics, colors and designs. We recommend bringing comfortable clothes & shoes, sunglasses, mosquito repellent and sunscreen. The tour will include transportation, tickets to the national park, boat tour, lunch and the Guaitil tour."
  }
]

const AboutPage = ({data}) => (
  <Layout>
    <SEO title="Costa Rica Best Choice" />
    <div className="masthead__wrapper">
      <div className="masthead-tours"></div>
    </div>
    <div className="block-simple__bg">
      <div className="block-simple__wrapper">
        <h1 className="block-simple__heading">Tours</h1>
        <div className="block-simple__text">We prepare full day or mid day tours for couples, individuals  and families. At trusted and magical locations we take our guests to experience the lively and wild jungles of the country, or to submerge within the calmest and quietest locations to relax and enjoy. We adapt the tours to key discoveries you wish to experience. For people of all ages and capacities, we develop custom and unique tours.</div>
        <div className="block-simple__text">With more than 10 years in the business we have been able to discover some of the most unique environments and inmersive activities for everyone to enjoy the Costa Rican culture. We can transport groups or families of all sizes and can comfort any special needs or request.</div>
      </div>
    </div>

    <div className="feature-card__wrapper-sm">
      <div className="feature-card">
        <h2 className="feature-card__heading">Recommended tours</h2>
          <FeatureCards features={features}/>
      </div>
    </div>

  </Layout>
)

export default AboutPage

export const toursQuery = graphql`
query {
  allContentfulTours {
    nodes {
      title
    }
  }
}
`
